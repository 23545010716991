import React from "react";
import "./LottieTextSection.css";
import LottieAnimation from "../LottieAnimation/LottieAnimation";
// import Lott
import "animate.css/animate.min.css";
import useWindowDimensions from "./useWindowDimensions";
// import ScrollAnimation from "react-animate-on-scroll";
// const isMobile = false;

function LottieTextSection({
  title,
  textContent,
  lottieLeftBool,
  lottie,
  learnMoreLink,
  buttonTitle = "",
  color,
  lottieWidth = 280,
}) {
  // prettier-ignore
  const {  width } = useWindowDimensions();

  const isMobile = width <= 600;

  return (
    <React.Fragment>
      <div
        style={{ backgroundColor: color }}
        className="position-relative overflow-hidden text-center text-light lottie-section-container"
      >
        <div className="row category-img-container-3">
          {lottieLeftBool && (
            <>
              <div className="lottie-section" style={{ overflow: "hidden" }}>
                {/* <ScrollAnimation animateIn="slideInLeft" duration={1}> */}
                {/* <div className="lottie-center"> */}
                {isMobile ? (
                  <div style={{ width: "100%" }}>
                    <LottieAnimation lottieAnimationJson={lottie} />
                  </div>
                ) : (
                  <div style={{ width: 450 }}>
                    <LottieAnimation lottieAnimationJson={lottie} />
                  </div>
                )}
                {/* <LottieAnimation src={lottie} width={450} /> */}
                {/* </div> */}
                {/* </ScrollAnimation> */}
              </div>
              <div className="text-section">
                {/* <ScrollAnimation animateIn="slideInRight" duration={1}> */}
                <h2 className="about-job-header-1">{title}</h2>
                <div className="about-description">{textContent}</div>
                {learnMoreLink && (
                  <div>
                    <a href={learnMoreLink} className="get-in-touch-btn-1">
                      {buttonTitle !== "" ? buttonTitle : "Contact Us"}
                    </a>
                  </div>
                )}
                {/* </ScrollAnimation> */}
              </div>
            </>
          )}

          {!lottieLeftBool && isMobile && (
            <>
              <div className="lottie-section-left">
                {/* <ScrollAnimation animateIn="slideInRight" duration={1}> */}
                <div style={({ width: lottieWidth }, { height: 250 })}>
                  <LottieAnimation
                    lottieAnimationJson={lottie}
                    width={lottieWidth}
                    height={250}
                  />
                </div>
                {/* </ScrollAnimation> */}
              </div>
              <div className="text-section-left">
                {/* <ScrollAnimation animateIn="slideInLeft" duration={1}> */}
                <h2 className="about-job-header-1">{title}</h2>
                <div className="about-description">{textContent}</div>
                {learnMoreLink && (
                  <div>
                    <a href={learnMoreLink} className="get-in-touch-btn-1">
                      {buttonTitle !== "" ? buttonTitle : "Contact Us"}
                    </a>
                  </div>
                )}
                {/* </ScrollAnimation> */}
              </div>
            </>
          )}

          {!lottieLeftBool && !isMobile && (
            <>
              <div className="text-section-left">
                {/* <ScrollAnimation animateIn="slideInLeft" duration={1}> */}
                <h2 className="about-job-header-1">{title}</h2>
                <div className="about-description">{textContent}</div>
                {learnMoreLink && (
                  <div>
                    <a href={learnMoreLink} className="get-in-touch-btn-1">
                      {buttonTitle !== "" ? buttonTitle : "Contact Us"}
                    </a>
                  </div>
                )}
                {/* </ScrollAnimation> */}
              </div>

              <div className="lottie-section-left">
                {/* <ScrollAnimation animateIn="slideInRight" duration={1}> */}
                <div style={{ height: 280, width: lottieWidth }}>
                  <LottieAnimation lottieAnimationJson={lottie} />
                </div>
                {/* </ScrollAnimation> */}
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default LottieTextSection;
