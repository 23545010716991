import { useLottie } from "lottie-react";
// import lottieAnimationJson from "../../resources/loading-car-lottie.json";

const LottieAnimation = ({ lottieAnimationJson, width = 300 }) => {
  const options = {
    animationData: lottieAnimationJson,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options);

  return View;
};

export default LottieAnimation;
