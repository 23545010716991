import React, { useState } from "react";
import "./Nav.css";
import NavItem from "./NavItem";
import devAngelLogo from "../../resources/Dev-Angels.png";

// import threeDCubeImage from "../../resources/3d-cube.svg";

// import Home from "../Home/Home";

const Nav = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // const colDropDownItemClicked = (col) => {
  //   setMenuOpen(false);
  //   setIsToggleOn(!isToggleOn);
  // };

  // const imgDropDownItemClicked = (pref) => {
  //   setMenuOpen(false);
  //   setIsImageToggleOn(!isImageToggleOn);
  //   setTextImagePref(pref);
  // };

  // const hideImageDropDown = () => {
  //   setIsImageToggleOn(false);
  // };

  const show = menuOpen ? "show" : "";
  // const navBorderRadius = menuOpen ? "navbar-radius" : "";

  return (
    <React.Fragment>
      <div className="nav-container">
        <nav className={"navbar navbar-expand-lg navbar-light custom-navbar"}>
          <a className="nav-link-logo" href="/">
            <img src={devAngelLogo} className="nav-logo" alt="dev angels" />
            {/* <h1 className="nav-company-name">Dev Angels</h1> */}
          </a>

          <button
            className="navbar-toggler no-bs-border hamburger"
            type="button"
            onClick={() => toggleMenu()}
          >
            <span className="navbar-toggler-icon hamburger"></span>
          </button>

          <div
            className={"collapse  navbar-collapse " + show}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mr-auto">
              <NavItem path="/" name="Home" />
              {/* <NavItem path="/portfolio" name="Portfolio" /> */}
              <NavItem path="/team" name="Team" />
              {/* <NavItem path="/team" name="Team" /> */}
              {/* <NavItem path="/galleryshows" name="Gallery Shows" /> */}
              <NavItem path="/contact" name="Contact" />
            </ul>
          </div>
        </nav>
      </div>
    </React.Fragment>
  );
};

export default Nav;
