import React from "react";
import "./Team.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";

import adamImg from "../../resources/adam-frankl.jpeg";
import peterImg from "../../resources/peter-bell.jpeg";
import gilImg from "../../resources/gil-headshot.png";
// import jpImg from "../../resources/jp.webp";
// import LottieTextSection from "../LottieTextSection/LottieTextSection";
// import moneyStack from "../../resources/lotties/money-stack.json";
// import safeDiamond from "../../resources/lotties/safe-diamond.json";
// import InfoAction from "../InfoAction/InfoAction";

// import ScrollAnimation from "react-animate-on-scroll";

function Team(props) {
  return (
    <React.Fragment>
      <Nav />
      <div className="container home-page-container team-container">
        <h1 className="my-4 text-center mb-5">Our Team</h1>
        {/* <ScrollAnimation animateIn="fadeIn" duration={2}> */}
        <div className="position-relative overflow-hidden text-center text-light ">
          <div className="row">
            {/* section  */}
            <div className="col-lg-12">
              <div className="category-img-container-2">
                <img src={peterImg} alt="Home" className="category-img-2" />
              </div>

              <h2 className="about-job-header">Peter Bell</h2>
              <a
                href="https://www.linkedin.com/in/peterfbell"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="fa fa-linkedin social social-icon-2"></i>
              </a>
              <p className="about-description team-text indent">
                Peter is an experienced entrepreneur, technologist and
                engineering leader. He is the founder of CTO Connection - an
                organization that helps engineering leaders to share problems
                and get answers. He wrote "Introducing GitHub"​ for O'Reilly,
                created the "Mastering GitHub"​ course for code school (now
                Pluralsight) and "Git and GitHub LiveLessons"​ for Pearson. He
                also taught Digital Literacy and Data Science at Columbia
                Business School as an adjunct for the MBA/EMBA program. He is a
                co-founder and co-organizer of the New York CTO School
                http://www.ctoschool.org - an organization in NYC devoted to
                creating the next generation of technical leaders and helps to
                organize CTO schools around the world. He also cofounded the
                Denver CTO Club - http://denverctoclub.org/ and is a member of
                the NY CTO Club.
              </p>
            </div>
            {/* end section  */}

            {/* section  */}
            <div className="col-lg-12">
              <div className="category-img-container-2">
                <img
                  src={adamImg}
                  alt="Category"
                  className="category-img-2"
                  style={{ marginTop: "5px" }}
                />
              </div>

              <h2 className="about-job-header">Adam Frankl</h2>
              <a
                href="https://www.linkedin.com/in/adamfrankl/"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="fa fa-linkedin social social-icon-2"></i>
              </a>
              <p className="about-description team-text indent">
                Adam is an expert in the arcane field of marketing to
                developers. A former software developer at Lockheed Skunk Works,
                Adam has co-founded or led marketing at over a dozen VC-backed
                startups, including 3 unicorns: JFrog, Neo4j, and Sourcegraph.
                As a partner and DevOps track chair at Alchemist Accelerator, he
                has coached dozens of early-stage startups. He has an
                engineering degree from Princeton and is the volunteer safety
                director of the SF-Marin Food Bank.
              </p>
            </div>
            {/* end section  */}
            {/* section  */}
            <div className="col-lg-12">
              <div className="category-img-container-2">
                <img src={gilImg} alt="Shield" className="category-img-2" />
              </div>

              <h2 className="about-job-header">Gil Silberman</h2>
              <a
                href="https://www.linkedin.com/in/gilsilberman/"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="fa fa-linkedin social social-icon-2"></i>
              </a>
              <p className="about-description team-text indent">
                Gil is Co-Founder and Managing Partner of Luca Ventures,
                responsible for operations and seed stage investments. He has an
                extensive background in startup law, venture finance, investing,
                and business. Immediately before founding Luca Ventures, Gil
                founded and served as Chief Legal Officer for Forge Global, a
                leading marketplace for equity in private companies, where he
                oversaw the setup and operation of SPVs to hold pre-IPO stock of
                venture-funded companies. Forge now has tens of billions of
                dollars under management and custodianship. Gil has also served
                as a partner at San Francisco Arts Fund, and founding lawyer of
                S2 Capital, an early-stage venture fund, as well as founder,
                Managing Partner, and manager of the venture operations of
                Britton Silberman Cervantez, a startup and venture finance law
                firm that was first lawyers to Craigslist, OpenTable, LinkedIn,
                On24, and hundreds of other startups, and later became the
                Emerging Companies Practice of Thelen Reid and Priest. Gil
                graduated from Princeton, where he studied Electrical
                Engineering and Computer Science, as well as Berkeley Law.
              </p>
            </div>
            {/* end section  */}
          </div>
        </div>
        {/* </ScrollAnimation> */}
      </div>
      {/* <InfoAction /> */}

      <Footer />
    </React.Fragment>
  );
}

export default Team;
