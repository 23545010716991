import React from "react";
import "./Home.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import { Button } from "../Button/Button";
import LottieAnimation from "../LottieAnimation/LottieAnimation";
import devOfficeLottie from "../../resources/lotties/dev-office.json";
import mobileMoneyLottie from "../../resources/lotties/mobile-money.json";
import phoneLottie from "../../resources/lotties/phone-cool.json";
import engImg from "../../resources/engflow.jpg";
import jfrogImg from "../../resources/jfrog.jpg";
import neo4jImg from "../../resources/neo4j.jpg";
import sgImg from "../../resources/SourceGraph.jpg";
import starImg from "../../resources/Stardog.jpg";
import tigerImg from "../../resources/TigerGraph.jpg";
import devAngelsLogo from "../../resources/Dev-Angels.png";
import metaBobLogo from "../../resources/metabob.png";
import snowMateLogo from "../../resources/snowmate.png";
import LottieTextSection from "../LottieTextSection/LottieTextSection";
const Home = () => {
  // const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    // setIsHovering(true);
  };

  const handleMouseOut = () => {
    // setIsHovering(false);
  };
  return (
    <div className="home-container">
      <Nav />
      <div
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 75,
        }}
      >
        {/* <h1 className="home-title">Dev Angels</h1> */}
        <div>
          <img
            src={devAngelsLogo}
            alt="dev angels logo"
            style={{ width: "100%", maxWidth: 400 }}
          />
        </div>
        <div style={{ width: 300, height: 300 }}>
          <LottieAnimation lottieAnimationJson={mobileMoneyLottie} />
        </div>
        <h1 className="home-sub-title">Devs Funding Devs</h1>

        <h1 className="home-text">
          The Dev Angel Syndicate is an angel group that makes early investments
          in developer-facing startups, including dev tools, DevOps, and
          databases.
        </h1>
        <div className="btn-container">
          <Button>Contact</Button>
        </div>

        {/* {isHovering && <p>hi</p>} */}
      </div>

      <hr style={{ marginTop: 60, marginLeft: 25, marginRight: 25 }} />
      <LottieTextSection
        title={
          <div className="about-title">
            <br></br>For Founders
          </div>
        }
        // color="#8a74ea"
        textContent={
          <div>
            <div>
              <h1 className="home-sub-title">
                {/* ARE YOU THE FOUNDER OF A PRE-REVENUE DEVELOPER-FACING STARTUP?{" "} */}
                Are you the founder of a pre-revenue developer-facing startup?
              </h1>{" "}
              <p className="home-subtext">
                Trying to raise money for product development, but frustrated
                that traditional investors and VCs want to see traction for a
                product–that doesn’t yet exist? Are you trying to get your ideas
                in front of an audience of senior developers and CTOs to
                jump-start your user community? Dev Angel Syndicate provides
                angel financing to developer-facing startups through online
                events attended by senior developers, CTOs, and startups
                veterans. If we like your idea and background and pass our
                diligence on your technology, we will provide $30,000-$100,000
                of financing in a few weeks. In addition, you’ll have the
                opportunity to present to dozens of potential early adopters of
                dev technology and endorsements from experienced folks that can
                help you raise venture financing.
              </p>
            </div>
          </div>
        }
        lottieLeftBool={true}
        lottie={phoneLottie}
        width={500}
      />
      <LottieTextSection
        title={
          <div className="about-title">
            <br></br>For Devs
          </div>
        }
        // color="#8a74ea"
        textContent={
          <div>
            <div>
              <h1 className="home-sub-title">
                {/* ARE YOU THE FOUNDER OF A PRE-REVENUE DEVELOPER-FACING STARTUP?{" "} */}
                Dev Angel Syndicate Membership is open to a senior developer or
                dev startup veteran interested in seeing a stream of dev tool
                startups and possibly investing in them.{" "}
              </h1>{" "}
              <p className="home-subtext">
                There is no commitment to membership. Unlike other angel
                syndicates, there are no management fees, administrative fees,
                or set-up fees. The syndicate is not a fund–you interact with
                the founders and make your own choices. If you like a founder
                presentation and generate enough interest from your peers, you
                can choose to invest. The syndicate handles the paperwork
                through AngelList. The minimum investment is $1000. Members must
                be professional software developers, executives, or CTOs and
                meet the SEC’s definition of an accredited investor, which means
                you must be able to document that your income for each of the
                last two years is at least $200,000. Alternatively, you could be
                a startup veteran and show that you have at least $1 million in
                investable assets.
              </p>
            </div>
          </div>
        }
        lottieLeftBool={true}
        lottie={devOfficeLottie}
        width={500}
      />

      <h1 className="home-sub-title">Dev Angels has invested in:</h1>
      <div className="img-container">
        <a href="https://www.engflow.com/" target="_blank" rel="noreferrer">
          <img src={engImg} className="home-img" alt="Eng" />
        </a>
        <a href="https://www.tigergraph.com/" target="_blank" rel="noreferrer">
          <img src={tigerImg} className="home-img" alt="Tiger" />
        </a>
        <a
          href="https://sourcegraph.com/search"
          target="_blank"
          rel="noreferrer"
        >
          <img src={sgImg} className="home-img" alt="SG" />
        </a>
        <a href="https://neo4j.com/" target="_blank" rel="noreferrer">
          <img src={neo4jImg} className="home-img" alt="neo4J" />
        </a>
        <a href="https://www.jfrog.com/" target="_blank" rel="noreferrer">
          <img src={jfrogImg} className="home-img" alt="jFrog" />
        </a>
        <a href="https://www.stardog.com/" target="_blank" rel="noreferrer">
          <img src={starImg} className="home-img" alt="Star" />
        </a>
        <a href="https://metabob.com/" target="_blank" rel="noreferrer">
          <img src={metaBobLogo} className="home-img" alt="Metabob" />
        </a>
        <a href="https://www.snowmate.io/" target="_blank" rel="noreferrer">
          <img src={snowMateLogo} className="home-img" alt="Snowmate" />
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
