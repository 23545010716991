import React from "react";

/* ---------- css ---------- */
import "bootstrap/dist/css/bootstrap.css";
import "./Footer.css";

import devAngelsLogo from "../../resources/dev-angels-logo-only.png";

/* ---------- images ---------- */
// import cubeLogo from "../../resources/3d-cube.svg";
// import cloudSurfLogo from "../../resources/logos/cloud-logo-black.svg";

function Footer(props) {
  return (
    <React.Fragment>
      <div className="footer-container">
        <div className="footer-wrapper">
          {/* <img src={cloudSurfLogo} className="footer-company-icon"></img> */}
          {/* <p className="footer-company-name">CloudSurf Software</p> */}
          <img src={devAngelsLogo} style={{ width: 100 }} alt="dev angels" />
          <div className="footer-icons">
            {/* <a href="" target="_blank">
              <i className="fa fa-facebook footer-icon"></i>
            </a>
            <a href="" target="_blank">
              <i className="fa fa-linkedin footer-icon"></i>
            </a>
            <a href="" target="_blank">
              <i className="fa fa-instagram footer-icon"></i>
            </a> */}
          </div>
          {/* footer-icons */}
          <p className="footer-text">&copy; 2022 DevAngels</p>
          <p className="footer-text">
            Powered by{" "}
            <a
              href="https://cloudsurfsoftware.com/"
              target="_blank"
              rel="noreferrer"
            >
              Cloudsurf
            </a>{" "}
          </p>
        </div>
        {/* footer-wrapper */}
      </div>
      {/* footer-container */}
    </React.Fragment>
  );
}

export default Footer;
